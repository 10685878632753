<template>
  <div class="page-admin-branches">
		<form class="form-access-ips" @submit.prevent="">
			<p class="title">Добавить IP адрес</p>
			<div class="inputs-wrap">
				<v-select label="Филиал" group-class="affiliate" :items="lists.branches" :value="formBranchId" @input="branchFind($event)" placeholder="Выберите филиал" minWidth="200px" maxWidth="242px" />
				<input-action-group label="IP адрес" v-model="branch.access_ips" minWidth="250px" />
				<button class="button" :disabled="load || !branch.id" @click="branchUpdate()">Сохранить</button>
			</div>
		</form>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters(['lists']),
	},
  data() {
		return {
			load: false,
			formBranchId: null,
			branch: {
				id: null,
				access_ips: null
			}
		}
	},
	methods: {
		branchFind(id) {
			this.load = true
			this.formBranchId = id
			api.branch.find(id)
				.then((data) => {
					this.load = false
					this.branch = data.data.data
				})
		},
		branchUpdate() {
			this.load = true
			api.branch.update(this.branch)
				.then((data) => {
					this.load = false
					this.$messageSuccess('Данные успешно сохранены!')
				})
		}
	}
}
</script>

<style lang="sass" scoped>
.form-access-ips
	padding: 20px
	background: rgba(183,180,181,0.2)
	border-radius: 5px
	.title
		font-size: 15px
		font-weight: 700
		text-align: center
		text-transform: uppercase
		margin: 0 0 15px 0
	.inputs-wrap
		display: flex
		gap: 0 20px
	.button
		max-width: 127px
		margin-top: 22px
</style>